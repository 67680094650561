.Welcome {
  position: relative;
}

.Welcome-image {
  width: 752px;
}

.Welcome-wrap {
  position: absolute;
  width: 696px;
  height: 285px;
  background-image: url("./assets/bg.png");
  background-size: cover;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Welcome-binding {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Welcome-binding > svg {
  height: 100px;
}

.Welcome-device {
  margin-top: 16px;
  font-size: 18px;
}

.Welcome-device-id {
  color: #3370FF;
}