@import '~antd/dist/antd.css';

.App-header {
  position: relative;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 52px 0 16px;
  background-image: url('./assets/header-bg.png');
  box-sizing: border-box;
}

.App-header > h1 {
  font-size: 26px;
  color: #FFF;
  -webkit-app-region: drag;
}

.App-drag {
  flex: 1;
  height: 56px;
  -webkit-app-region: drag;
}

.App-logo {
  width: 32px;
  height: 32px;
  pointer-events: none;
  margin-right: 12px;
  -webkit-app-region: drag;
}

.App-close-btn {
  position: absolute;
  font-size: 20px;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #B5B9BF;
}
.App-close-btn:hover,
.App-deviceId-btn:hover {
  opacity: .7;
}

.App-container {
  width: 100%;
  height: calc(100vh - 56px);
  padding: 24px;
  box-sizing: border-box;
  overflow: auto;
}

.App-deviceId-btn {
  padding: 0 16px;
  color: #B5B9BF;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
